<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <date-range-input :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <div
                v-if="!isLoading && !hasData"
                class="l-stack l-center l-padded"
            >
                {{ $t('noData') }}
            </div>

            <apex-chart
                v-if="!isLoading && hasData"
                height="420px"
                :options="chartOptions"
                :series="series"
            />

            <div v-if="!isLoading && hasData" class="text-right">
                <p>
                    {{ $t(currentStyle.textFalse) }}:
                    <strong>{{ falseHoursHumanized }}</strong>
                </p>

                <p>
                    {{ $t(currentStyle.textTrue) }}:
                    <strong>{{ trueHoursHumanized }}</strong>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'
import ApexChart from 'vue-apexcharts'

import { httpHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

export default {
    name: 'AssetStatusChartView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        dataType: {
            type: [String],
            required: true,
            default: 'd1',
        },
        labelPair: {
            type: [String],
            required: true,
            default: 'alert',
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            rawTimeRange: {
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day'),
            },
            isLoading: false,
            styles: {
                alert: {
                    textTrue: 'alarm',
                    textFalse: 'ok',
                    colorTrue: '#d32f2f',
                    colorFalse: '#41b883',
                },
                circuit: {
                    textTrue: 'circuitClosed',
                    textFalse: 'circuitOpen',
                    colorTrue: '#41b883',
                    colorFalse: '#d32f2f',
                },
                door: {
                    textTrue: 'open',
                    textFalse: 'closed',
                    colorTrue: '#d32f2f',
                    colorFalse: '#41b883',
                },
                flood: {
                    textTrue: 'flood',
                    textFalse: 'noFlood',
                    colorTrue: '#d32f2f',
                    colorFalse: '#41b883',
                },
                operation: {
                    textTrue: 'on',
                    textFalse: 'off',
                    colorTrue: '#41b883',
                    colorFalse: '#d32f2f',
                },
                maintenance: {
                    textTrue: 'maintenance',
                    textFalse: 'noMaintenance',
                    colorTrue: '#d32f2f',
                    colorFalse: '#41b883',
                },
                malfunction: {
                    textTrue: 'malfunction',
                    textFalse: 'noMalfunction',
                    colorTrue: '#d32f2f',
                    colorFalse: '#41b883',
                },
            },
        }
    },
    computed: {
        currentStyle() {
            return this.styles[this.labelPair]
        },
        hasData() {
            return this.series[0].data.length > 0
        },
        falseHoursHumanized() {
            return this.getHoursHumanized(
                this.series[0].data.filter(item => item.isOk)
            )
        },
        trueHoursHumanized() {
            return this.getHoursHumanized(
                this.series[0].data.filter(item => !item.isOk)
            )
        },
    },
    beforeRouteUpdate(to, from, next) {
        // reload the data on route update (ex: switch between d1 and d2 charts)
        this.loadHistory(this.rawTimeRange)
        next()
    },
    methods: {
        async loadHistory({ startDate, endDate }) {
            this.rawTimeRange = { startDate, endDate }
            this.isLoading = true
            this.chartOptions.xaxis.min = startDate
            this.chartOptions.xaxis.max = endDate
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []

            const timestampMin = encodeURIComponent(this.startDate.format())
            const timestampMax = encodeURIComponent(this.endDate.format())

            let url =
                'measurements/?' +
                `timestamp_min=${timestampMin}` +
                `&timestamp_max=${timestampMax}` +
                `&tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = [...results, ...data.results]
                url = data.next
            }

            const respBefore = await httpHelper.get(
                'measurements/?' +
                    `timestamp_max=${timestampMin}` +
                    `&tracker=${this.id}` +
                    '&fields=timestamp,sensor_data' +
                    '&limit=1'
            )
            const lastBefore = respBefore.data.results[0]
            if (lastBefore) {
                lastBefore.timestamp = this.startDate.format()
                results = [...results, lastBefore]
            }

            const respAfter = await httpHelper.get(
                'measurements/?' +
                    `timestamp_min=${timestampMax}` +
                    `&tracker=${this.id}` +
                    '&fields=timestamp,sensor_data' +
                    '&limit=1'
            )
            const firstAfter = respAfter.data.results[0]
            if (firstAfter) {
                firstAfter.timestamp = this.endDate.format()
                results = [firstAfter, ...results]
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            const chartData = results
                .filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        this.dataType
                    )
                )
                .map(item => [
                    item.timestamp,
                    !!item.sensor_data[this.dataType],
                ])
                .reverse()

            const dataFalse = []
            const dataTrue = []

            let lastSameDataIndex = 0
            while (lastSameDataIndex < chartData.length - 1) {
                let item = chartData[lastSameDataIndex]

                while (
                    lastSameDataIndex < chartData.length - 1 &&
                    chartData[lastSameDataIndex][1] === item[1]
                ) {
                    lastSameDataIndex++
                }

                const data = {
                    x: item[1]
                        ? this.$t(this.currentStyle.textTrue)
                        : this.$t(this.currentStyle.textFalse),
                    isOk: !item[1],
                    y: [
                        new Date(item[0]).getTime(),
                        new Date(chartData[lastSameDataIndex][0]).getTime(),
                    ],
                    fillColor: item[1]
                        ? this.currentStyle.colorTrue
                        : this.currentStyle.colorFalse,
                }

                if (item[1]) {
                    dataFalse.push(data)
                } else {
                    dataTrue.push(data)
                }
            }

            this.series = [
                {
                    data: [...dataFalse, ...dataTrue],
                },
            ]
        },
        getHoursHumanized(results) {
            const duration = results.reduce((accDuration, item) => {
                const dateStart = moment(item.y[0])
                const dateEnd = moment(item.y[1])
                return accDuration.add(moment.duration(dateEnd.diff(dateStart)))
            }, moment.duration(0))

            const hours = duration.asHours()
            duration.subtract(parseInt(hours), 'hours')
            const minutes = duration.asMinutes()

            return (
                (parseInt(hours) < 10 ? '0' : '') +
                parseInt(hours) +
                'h ' +
                (parseInt(minutes) < 10 ? '0' : '') +
                parseInt(minutes) +
                'm'
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "alarm": "Alarm",
        "circuitClosed": "DI Closed",
        "circuitOpen": "DI Open",
        "closed": "Closed",
        "flood": "Flood",
        "ok": "OK",
        "on": "On",
        "off": "Off",
        "open": "Open",
        "maintenance": "Maintenance",
        "malfunction": "Malfunction",
        "noData": "No data for this time period",
        "noFlood": "OK",
        "noMaintenance": "OK",
        "noMalfunction": "Status OK"
    },
    "de": {
        "alarm": "Alarm",
        "circuitClosed": "DI Geschlossen",
        "circuitOpen": "DI Offen",
        "closed": "Tür zu",
        "flood": "Überflutung",
        "ok": "OK",
        "on": "Ein",
        "off": "Aus",
        "open": "Tür offen",
        "maintenance": "Wartung",
        "malfunction": "Störung",
        "noData": "Keine Daten für diese Zeitperiode",
        "noFlood": "OK",
        "noMaintenance": "OK",
        "noMalfunction": "Status OK"
    },
    "it": {
        "alarm": "Allarme",
        "circuitClosed": "DI Chiuso",
        "circuitOpen": "DI Aperto",
        "closed": "Chiuso",
        "flood": "Flood",
        "ok": "OK",
        "on": "On",
        "off": "Off",
        "open": "Aperto",
        "maintenance": "Maintenance",
        "malfunction": "Malfunction",
        "noData": "Nessun dato per questo periodo di tempo",
        "noFlood": "OK",
        "noMaintenance": "OK",
        "noMalfunction": "Status OK"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

.text-right {
    text-align: right;
}

@include respond-to('for-tablet-down') {
    .chart-holder {
        display: block;
    }

    .chart {
        border: none;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
